import React, { Component } from "react";
import { Bar, Radar, Doughnut } from "react-chartjs-2";
import { Col, Grid, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";

import moment from "moment";
import PageTitle from "../../components/Text/PageTitle";
import SectionTitle from "../../components/Text/SectionTitle";
import { getStats } from "./actions";

import {
  setActivePark,
  toggleAddInspection,
  toggleEditCommunication,
  toggleViewGrowers,
} from "../App/actions";

import {
  Hortas,
  StyledContentWrap,
  StyledContentWrapFullHeight,
  StyledWrap,
  TotalParcelPerWeek,
  TotalParcelPerWeekWrap,
} from "./styled";
import { MenuItem, Select } from "@material-ui/core";

// import ChartDataLabels from "chartjs-plugin-datalabels";
// import { Chart } from "react-chartjs-2";

// Chart.register(ChartDataLabels);

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parklist: false,
      horta: null,
      contactListUser: null,
      waitingListUser: null,
      maintenanceListUser: null,
      inspectionListDays: null,
      parcelBadRatingHistory: null,
      goodParcelHistoryData: null,
      radarType: "HC",
    };
  }

  componentDidUpdate() {
    if (this.state.parklist === false && this.props.geoJson) {
      const parkListObject = this.props.geoJson.reduce((prev, curr) => {
        prev[curr.properties.numero] = curr.properties.local;
        return prev;
      }, {});
      this.setState({ parklist: parkListObject });
    }

    if (
      this.props.data &&
      this.props.data.inspetionsLessRatingYear &&
      !this.state.parcelBadRatingHistory
    ) {
      this.setBadRatingHistory();
    }
  }
  componentDidMount() {
    this.props.getStats();
  }

  // generateRadarData() {
  //   const { data, menuInspections, menuInterventions } = this.props;

  //   const maintenanceFreguesias = this.handleMaintenanceFreguesias();
  //   const labelM = maintenanceFreguesias
  //     ? Object.keys(maintenanceFreguesias)
  //     : [];
  //   const labelI = data.inespectionsTop5Freguesias
  //     ? data.inespectionsTop5Freguesias.map((i) => i.area)
  //     : [];

  //   // const uniqueLabelArray = [...labelM, ...labelI].filter((item, pos) => {
  //   //   return [...labelM, ...labelI].indexOf(item) === pos;
  //   // });
  //   const uniqueLabelArray = [...new Set([...labelM, ...labelI])];

  //   const inspectionsPerDay = [...uniqueLabelArray].map((x, i) => 0);
  //   const maintenancesPerDay = [...uniqueLabelArray].map((x, i) => 0);
  //   data.inespectionsTop5Freguesias &&
  //     data.inespectionsTop5Freguesias.map((inspection) => {
  //       inspectionsPerDay[uniqueLabelArray.indexOf(inspection.area)] =
  //         inspection.inspections;

  //       return null;
  //     });
  //   data.maintenanceTop5Freguesias &&
  //     Object.keys(maintenanceFreguesias).map((maintenance) => {
  //       maintenancesPerDay[uniqueLabelArray.indexOf(maintenance)] =
  //         maintenanceFreguesias[maintenance];
  //       return null;
  //     });
  //   // data.maintenancesPerDay
  //   return {
  //     labels: uniqueLabelArray,
  //     datasets: [
  //       {
  //         label: menuInterventions.name,
  //         backgroundColor: "rgba(30,92,161,1)",
  //         borderColor: "rgba(30,92,161,1)",
  //         borderWidth: 1,
  //         data: maintenancesPerDay,
  //       },
  //       {
  //         label: menuInspections.name,
  //         backgroundColor: "rgba(72,161,255,1)",
  //         borderColor: "rgba(72,161,255,1)",
  //         borderWidth: 1,
  //         data: inspectionsPerDay,
  //       },
  //     ],
  //   };
  // }

  generateDoughnutData() {
    const { data } = this.props;
    let globalParcels = 0;
    let totalParcels = 0;
    let totalAttrParcels = 0;
    let totalAvailabel = 0;
    let badValiation = 0;
    let goodValiation = 0;

    data?.totalParcels?.map((park) => {
      const hortaDetails = this.props.geoJson.find(
        (h) => h.properties.numero === park.pid
      );
      if (
        this.state.radarType === "HC" &&
        hortaDetails?.properties?.parkType !== "Hortas Comunitárias"
      ) {
        return null;
      }

      if (hortaDetails?.properties?.parkType === "Hortas nas Escolas") {
        return null;
      }

      if (
        this.state.radarType === "PC" &&
        hortaDetails?.properties?.parkType === "Hortas Comunitárias"
      ) {
        return null;
      }
      if (!hortaDetails?.properties?.parkType) return null;

      // console.table(
      //   hortaDetails?.properties?.parkType,
      //   hortaDetails?.properties,
      //   park
      // );

      globalParcels += Number(park.available);
      totalParcels += Number(park.attr);
      totalAttrParcels += Number(park.attr) - Number(park.available);
      totalAvailabel += Number(park.available) - Number(park.attr);
      return park;
    });

    if (this.state?.parcelBadRatingHistory) {
      Object.keys(this.state?.parcelBadRatingHistory)?.map((p) => {
        if (
          this.state?.parcelBadRatingHistory[p][0] &&
          this.state.radarType ===
            this.state?.parcelBadRatingHistory[p][0].parkType
        )
          badValiation += this.state?.parcelBadRatingHistory[p].length;
        return p;
      });
    }

    if (this.state?.goodParcelHistoryData) {
      Object.keys(this.state?.goodParcelHistoryData)?.map((p) => {
        if (
          this.state?.goodParcelHistoryData[p][0] &&
          this.state.radarType ===
            this.state?.goodParcelHistoryData[p][0].parkType
        )
          goodValiation += this.state?.goodParcelHistoryData[p].length;
        return p;
      });
    }

    return {
      labels: ["Não Attribuidas", "Bem mantidas", "Mal mantidas"],
      datasets: [
        {
          label: ["Não Atribuidas", "Bem mantidas", "Mal mantidas"],
          data: [totalAvailabel, goodValiation, badValiation],
          backgroundColor: [
            "rgba(54, 162, 235, 0.5)",
            "rgba(75, 192, 192, 0.5)",
            "rgba(255, 99, 132, 0.5)",
          ],
          borderColor: [
            "rgba(54, 162, 235, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(255, 99, 132, 1)",
          ],
          borderWidth: 2,
        },
        {
          label: ["Parcelas"],
          data: [globalParcels],
          backgroundColor: [
            "rgba(255, 206, 86, 0.5)",
            "rgba(153, 102, 255, 0.5)",
          ],
          borderColor: ["rgba(255, 206, 86, 1)", "rgba(153, 102, 255, 1)"],
          borderWidth: 2,
        },
      ],
    };
  }

  generateRadarData() {
    const { data } = this.props;

    const parkLabel = [];
    const parkAvailable = [];
    const parkOcupied = [];
    const parkLessRating = [];
    const parkEmpty = [];

    //need to calculate bad rating history
    const parcelBadRatingHistory = this.state.parcelBadRatingHistory || {};
    const listOfParcels = {};
    Object.keys(parcelBadRatingHistory)?.map((h) => {
      parcelBadRatingHistory[h]?.map((th) => {
        if (th.weekStart && !th.weekEnd) {
          //lets check if we added the parcel to tha parklist already
          let pacelInList = false;
          if (listOfParcels[th.park]) {
            pacelInList = listOfParcels[th.park].find(
              (p) => p.label === th.label
            );
          }
          // if (th.park === "VCJOLI")
          //   console.log(listOfParcels[th.park], th.park);
          if (!pacelInList)
            listOfParcels[th.park] = [
              ...(listOfParcels[th.park] || []),
              { ...th, id: th.origin || h },
            ];
        }
      });
    });

    data?.totalParcels?.map((park) => {
      const hortaDetails = this.props.geoJson.find(
        (h) => h.properties.numero === park.pid
      );
      if (
        this.state.radarType === "HC" &&
        hortaDetails?.properties?.parkType !== "Hortas Comunitárias"
      ) {
        return null;
      }

      if (
        this.state.radarType === "PC" &&
        hortaDetails?.properties?.parkType === "Hortas Comunitárias"
      ) {
        return null;
      }

      if (
        !hortaDetails ||
        !hortaDetails?.properties?.parkType ||
        hortaDetails?.properties?.parkType == "Hortas nas Escolas" ||
        !hortaDetails?.properties?.numero_ant
      )
        return null;
      parkLabel.push(hortaDetails?.properties?.numero_ant);
      parkAvailable.push(Number(park.available));
      //calculate percentage
      parkOcupied.push(
        ((Number(park.attr) * 100) / Number(park.available)).toFixed(2)
      );
      parkEmpty.push(Number(park.available) - Number(park.attr));

      //calculate the percentage of parcels with rating less than 1 from total Number(park.available)
      const thisParkPercentage = Number(
        ((listOfParcels[hortaDetails?.properties?.numero_ant]?.length || 0) *
          100) /
          Number(park.available)
      ).toFixed(2);
      parkLessRating.push(thisParkPercentage);

      // listOfParcels
    });

    return {
      labels: [...new Set(parkLabel)],
      datasets: [
        {
          label: "Atribuição %",
          data: parkOcupied,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
        {
          label: "% Avaliação <= 1",
          data: parkLessRating,
          backgroundColor: "rgba(226, 156, 21, 0.4)",
          borderColor: "rgba(226, 156, 21, 1)",
          borderWidth: 1,
        },
        // {
        //   label: "Por Atribuir",
        //   data: parkEmpty,
        //   backgroundColor: "rgba(255, 99, 132, 0)",
        //   borderColor: "rgba(255, 99, 132,  0)",
        //   borderWidth: 0,
        //   hidden: true,
        // },
        // {
        //   label: "Atribuídas",
        //   data: parkAvailable,
        //   backgroundColor: "rgba(255, 99, 132,  0)",
        //   borderColor: "rgba(255, 99, 132,  0)",
        //   borderWidth: 0,
        //   hidden: true,
        // },
      ],
    };
  }

  generateGraphData() {
    const { data, menuInspections, menuInterventions } = this.props;

    const today = new Date();
    // const startDateWeek = moment()
    //   .subtract(12, "months")
    //   .isoWeek();
    // const endDateWeek = moment().isoWeek();

    // // const totalWeeks = Math.max(
    //   moment(new Date(today.getUTCFullYear(), 11, 31)).isoWeek(),
    //   moment(new Date(today.getUTCFullYear(), 11, 31 - 7)).isoWeek()
    // );

    // const totalDays = new Date(
    //   today.getUTCFullYear(),
    //   today.getUTCMonth(),
    //   0
    // ).getDate();

    // const inspectionsHCPerDay = [...Array(totalWeeks)].map((x, i) => 0);
    // const inspectionsOthersPerDay = [...Array(totalWeeks)].map((x, i) => 0);

    const inspectionsHCPerDay = {};
    const inspectionsOthersPerDay = {};
    const inspectionsVCPerDay = {};
    const inspectionsPCPerDay = {};
    const inspectionsOCPerDay = {};
    let yearWeeks = [];
    data.inspectionsPerWeek &&
      data.inspectionsPerWeek.map((inspection) => {
        const inspDate = moment(inspection.week, "YYYY-MM-DD 00:00:00+00");
        const inspWeek = Number(
          `${inspDate.format("YY")}${
            inspDate.isoWeek() < 10
              ? `0${inspDate.isoWeek()}`
              : inspDate.isoWeek()
          }`
        );
        // const inspWeekInternal = `${inspDate.format("YYYY")}${inspDate.isoWeek()}`;
        // const inspMth =
        //   moment(inspection.week, "YYYY-MM-DD 00:00:00+00").format("YYYY") +
        //   `${inspWeek}`;
        if (!yearWeeks.includes(inspWeek)) {
          yearWeeks.push(inspWeek);
          inspectionsHCPerDay[inspWeek] = 0;
          inspectionsVCPerDay[inspWeek] = 0;
          inspectionsPCPerDay[inspWeek] = 0;
          inspectionsOCPerDay[inspWeek] = 0;
          inspectionsOthersPerDay[inspWeek] = 0;
        }
        if (
          inspection["número_ant"] &&
          inspection["número_ant"]?.match(/HC|HA/)
        ) {
          inspectionsHCPerDay[inspWeek] =
            (inspectionsHCPerDay[inspWeek] || 0) + 1;
        } else if (
          inspection["número_ant"] &&
          inspection["número_ant"]?.match(/PC/)
        ) {
          inspectionsPCPerDay[inspWeek] =
            (inspectionsPCPerDay[inspWeek] || 0) + 1;
        } else if (
          inspection["número_ant"] &&
          inspection["número_ant"]?.match(/VC/)
        ) {
          inspectionsVCPerDay[inspWeek] =
            (inspectionsVCPerDay[inspWeek] || 0) + 1;
        } else if (
          inspection["número_ant"] &&
          inspection["número_ant"]?.match(/OC/)
        ) {
          inspectionsOCPerDay[inspWeek] =
            (inspectionsOCPerDay[inspWeek] || 0) + 1;
        } else if (inspection["número_ant"]) {
          inspectionsOthersPerDay[inspWeek] =
            (inspectionsOthersPerDay[inspWeek] || 0) + 1;
        }
        return null;
      });
    // const inspectionsHCPerDay = [...Array(totalWeeks)].map((x, i) => 0);

    // data.maintenancesPerWeek &&
    //   data.maintenancesPerWeek.map((maintenance) => {
    //     const inspWeek = moment(
    //       maintenance.week,
    //       "YYYY-MM-DD 00:00:00+00"
    //     ).isoWeek();
    //     maintenancesPerDay[inspWeek] = maintenance.maintenances;
    //     return null;
    //   });
    // data.maintenancesPerDay
    return {
      labels: yearWeeks,
      datasets: [
        {
          label: "PC",
          backgroundColor: "rgba(30,92,161,.4)",
          borderColor: "rgba(30,92,161,.4)",
          data: Object.keys(inspectionsPCPerDay).map(
            (x) => inspectionsPCPerDay[x]
          ),
        },
        {
          label: "VC",
          backgroundColor: "rgba(30,92,161,.6)",
          borderColor: "rgba(30,92,161,.6)",
          data: Object.keys(inspectionsVCPerDay).map(
            (x) => inspectionsVCPerDay[x]
          ),
        },
        // {
        //   label: "OC",
        //   backgroundColor: "rgba(30,92,161,.8)",
        //   borderColor: "rgba(30,92,161,.8)",
        //   data: Object.keys(inspectionsOCPerDay).map(
        //     (x) => inspectionsOCPerDay[x]
        //   ),
        // },

        {
          label: "HC",
          backgroundColor: "rgba(72,161,255,1)",
          borderColor: "rgba(72,161,255,1)",
          data: Object.keys(inspectionsHCPerDay).map(
            (x) => inspectionsHCPerDay[x]
          ),
        },
        // {
        //   label: "Outros",
        //   backgroundColor: "rgba(30,92,161,1)",
        //   borderColor: "rgba(30,92,161,1)",
        //   data: Object.keys(inspectionsOthersPerDay).map(
        //     (x) => inspectionsOthersPerDay[x]
        //   ),
        // },
      ],
    };
  }

  itemPerRout(data, icon) {
    return data.map((park, i) => {
      if (!this.state.parklist[park.pid]) return null;
      return (
        <li key={i}>
          <span>
            <i className={`icon-${icon} icons`} />{" "}
            {park.inspections || park.maintenances}{" "}
          </span>
          {park.pid} {this.props.role !== "EV" && this.state.parklist[park.pid]}
        </li>
      );
    });
  }

  itemPerRoutNotVisited(data, icon) {
    return data.map((park, i) => {
      const hortaDetails = this.props.geoJson.find(
        (h) => h.properties.numero === park
      );
      if (!this.state.parklist[park]) return null;
      return (
        <li key={i}>
          <span>
            <i className={`icon-${icon} icons`} />{" "}
          </span>
          {hortaDetails.properties.name}
        </li>
      );
    });
  }

  handleMaintenanceFreguesias() {
    const { data } = this.props;
    let totalMaintenancePerArea = {};
    if (data && data.maintenanceTop5Freguesias) {
      data.maintenanceTop5Freguesias.map((maint) => {
        // encrement the array
        totalMaintenancePerArea[maint.MaintenanceArea || maint.InspectionArea] =
          (totalMaintenancePerArea[
            maint.MaintenanceArea || maint.InspectionArea
          ] || 0) + parseInt(maint.maintenances, 10);
        return false;
      });
    }
    return totalMaintenancePerArea;
  }

  getMultilevelParcelList() {
    const data = this.props.data;
    let hortas = {};
    //30 or 60 days query
    let workingData = this.state.inspectionListDays
      ? data.elementInspectionsLeft
      : data.elementInspections60Left;
    if (data && workingData) {
      workingData.map((ele) => {
        if (ele.label.match(/Parcela|parcela|Lote|lote/)) {
          hortas = {
            ...hortas,
            [ele.pid]: [
              ...(hortas[ele.pid] || []),
              { id: ele.id, l: ele.label, origin: ele.origin },
            ],
          };
        }
      });
      return hortas;
    }
    return {};
  }

  setBadRatingHistory() {
    const { data } = this.props;
    let parcelGroupData = {};
    let parcelHistoryData = {};
    //first lets group the data
    data.inspetionsLessRatingYear &&
      data.inspetionsLessRatingYear.map((parcel) => {
        if (parcel.label && parcel.label.match(/Parcela|parcela|Lote|lote/)) {
          parcelGroupData[parcel.origin || parcel.ElementId] = [
            ...(parcelGroupData[parcel.origin || parcel.ElementId] || []),
            parcel,
          ];
        }
      });
    // console.log(data.inspetionsLessRatingYear);
    //second check for dates while it was less than 1 and add to output
    Object.keys(parcelGroupData).map((gD) => {
      let startDate,
        endDate = Number(
          `${moment().format("YY")}${
            moment().isoWeek() < 10
              ? `0${moment().isoWeek()}`
              : moment().isoWeek()
          }`
        );

      //lets get the start and end date for this number
      parcelGroupData[gD]
        // .sort((a, b) => moment(b.date) - moment(a.date))
        .map((history) => {
          // if (history["número_ant"] === "VCJOLI")
          let parkType = "HC";
          const hortaDetails = this.props.geoJson.find(
            (h) => h.properties.numero_ant === history["número_ant"]
          );

          if (hortaDetails?.properties?.parkType === "Hortas nas Escolas") {
            return null;
          }

          if (hortaDetails?.properties?.parkType !== "Hortas Comunitárias") {
            parkType = "PC";
          }

          //if we already added lets exit
          // if (parcelHistoryData[history.origin || history.ElementId])
          //   return null;

          //   console.log(history, moment(history.date).isoWeek(), startDate);
          // if (Number(history.rating) > 1) {
          //   // console.log(parcelHistoryData[history.ElementId]);
          //   startDate = moment(history.date);
          //   startDate = Number(
          //     `${startDate.format("YY")}${
          //       startDate.isoWeek() < 10
          //         ? `0${startDate.isoWeek()}`
          //         : startDate.isoWeek()
          //     }`
          //   );
          //   //check if we already have a previe good rating
          //   const alreadyRegistered =
          //     goodParcelHistoryData[history.origin || history.ElementId] &&
          //     goodParcelHistoryData[history.origin || history.ElementId].find(
          //       (ph) => {
          //         return ph.weekStart < startDate && !ph.weekEnd;
          //       }
          //     );
          //   if (alreadyRegistered) return null; //lets not save if we have a previous open bad ratting

          //   //lets check if we already have a previe bad rating
          //   const alreadyRegisteredBad =
          //     parcelHistoryData[history.origin || history.ElementId] &&
          //     parcelHistoryData[history.origin || history.ElementId].find(
          //       (ph) => {
          //         return ph.weekStart < startDate && !ph.weekEnd;
          //       }
          //     );
          //   if (alreadyRegisteredBad) {
          //     //we should remove this entry from the bad rating
          //     delete parcelHistoryData[history.origin || history.ElementId];
          //   }

          //   goodParcelHistoryData[history.origin || history.ElementId] = [
          //     ...(goodParcelHistoryData[history.origin || history.ElementId] ||
          //       []),
          //     {
          //       pid: history.pid,
          //       label: history.label,
          //       park: history["número_ant"],
          //       start: history.date,
          //       weekStart: startDate,
          //       origin: history.origin,
          //       parkType,
          //     },
          //   ];
          // } else
          // if (history["número_ant"] === "HCALTG") {
          //   console.log(history);
          // }
          if (history?.rating) {
            // console.log(goodParcelHistoryData[history.ElementId]);
            startDate = moment(history.date);
            startDate = Number(
              `${startDate.format("YY")}${
                startDate.isoWeek() < 10
                  ? `0${startDate.isoWeek()}`
                  : startDate.isoWeek()
              }`
            );

            const thisId = history.origin || history.ElementId;
            //check if we already have a previe bad rating
            // const alreadyRegistered =
            //   parcelHistoryData[thisId] &&
            //   parcelHistoryData[thisId].find((ph) => {
            //     return ph.weekStart < startDate && !ph.weekEnd;
            //   });
            // if (alreadyRegistered) return null; //lets not save if we have a previous open bad ratting

            parcelHistoryData[thisId] = [
              ...(parcelHistoryData[thisId] || []),
              {
                pid: history.pid,
                label: history.label,
                park: history["número_ant"],
                start: history.date,
                weekStart: startDate,
                origin: history.origin,
                thisId,
                parkType,
                rating: Number(history.rating),
              },
            ];
          }
        });
    });
    // console.log(parcelHistoryData); //2915

    //lets create the output
    let parcelBadRatingHistory = {};
    let goodParcelHistoryData = {};

    Object.keys(parcelHistoryData)?.map((p) => {
      const parcelHistory = parcelHistoryData[p];

      const dateSorted = parcelHistory?.sort(
        (a, b) => moment(b.start) - moment(a.start)
      );
      const latestRecord = dateSorted[0];

      // if (latestRecord.park === "HCALTG") {
      // console.log(parcelHistory);
      // }

      if (latestRecord.rating <= 1) {
        parcelBadRatingHistory[latestRecord.thisId] = [latestRecord];
      } else {
        goodParcelHistoryData[latestRecord.thisId] = [latestRecord];
      }
    });
    // console.log(
    //   Object.keys(parcelBadRatingHistory).length,
    //   Object.keys(goodParcelHistoryData).length
    // );
    if (!this.state.parcelBadRatingHistory)
      this.setState({
        parcelBadRatingHistory,
        goodParcelHistoryData,
      });
  }

  parksWithMoreBadParcels() {
    const parcelBadRatingHistory = this.state.parcelBadRatingHistory;
    if (!this.state.parcelBadRatingHistory) return null;
    const listOfParcels = {};
    Object.keys(parcelBadRatingHistory).map((h) => {
      parcelBadRatingHistory[h].map((th) => {
        if (th.weekStart && !th.weekEnd) {
          //lets check if we added the parcel to tha parklist already
          let pacelInList = false;
          if (listOfParcels[th.park]) {
            pacelInList = listOfParcels[th.park].find(
              (p) => p.label === th.label
            );
          }
          // if (th.park === "VCJOLI")
          //   console.log(listOfParcels[th.park], th.park);
          if (!pacelInList)
            listOfParcels[th.park] = [
              ...(listOfParcels[th.park] || []),
              { ...th, id: th.origin || h },
            ];
        }
      });
    });

    return Object.keys(listOfParcels)
      .sort((a, b) => listOfParcels[b].length - listOfParcels[a].length)
      .map((horta) => {
        const thisPark = listOfParcels[horta];
        return (
          <Tooltip
            title={
              <div>
                {thisPark.map((p) => (
                  <div key={p.id}>{p.label}</div>
                ))}
              </div>
            }
          >
            <li key={horta}>
              <div className={"icon"}>
                <>{thisPark.length}</>
              </div>
              <div className={"content"}>{horta}</div>
            </li>
          </Tooltip>
        );
      });
  }

  getAtributedContacts() {
    // const currentweek = moment().isoWeek();
    // let outputWeeks = [...Array(currentweek)].map((x, i) => 0);
    let outputWeeks = [...Array(52)].map((x, i) => {});
    let weekRun = [];

    let totalActiveUser = {};
    let totalAtributedUser = {};

    if (!this.props.data?.getcontactHistory) return null;

    //lets now add it to the output
    outputWeeks = outputWeeks.map((a, week) => {
      let currentweekDay = moment().subtract(51 - week, "weeks");
      const currentweek = Number(
        `${currentweekDay.format("YY")}${
          currentweekDay.isoWeek() < 10
            ? `0${currentweekDay.isoWeek()}`
            : currentweekDay.isoWeek()
        }`
      );
      weekRun.push(currentweek);

      return week;
    });

    /**
     * there is a history prop we need to create a sum
     * we add the user to the totalActiveUser
     * then we need to check the history and when the status change to 1 we add it to the totalAtributedUser and remove it from the totalActiveUser
     * if the history status object is 0 we should add it back at that date to the totalActiveUser and remove it from the totalAtributedUser
     * the totalActiveUser and totalAtributedUser is an array of week number and sum of users
     */
    let totalNumberOfUsers = [];
    let totalNumberOfAtributedUsers = [];
    this.props.data?.getcontactHistory?.map((a) => {
      //let add a week Number YYWW to the object for easy retrieval
      let currentweekDay = moment(a.createdAt); // this is timestamp lets convert it to week
      let thisWeekCreatedAt = Number(
        `${currentweekDay.format("YY")}${
          currentweekDay.isoWeek() < 10
            ? `0${currentweekDay.isoWeek()}`
            : currentweekDay.isoWeek()
        }`
      );

      if (a.status === 1 || a.status === 6)
        totalNumberOfAtributedUsers.push(a.id);
      //we have to all all to then remove with history
      if (
        // a.status === 1 ||
        // a.status === 6 ||
        a.status === 0
        // a.status === 7 ||
        // a.status === 9 ||
        // a.status === 10 ||
        // a.status === 11
      )
        totalNumberOfUsers.push(a.id);

      //lets find the index of the week in weekRun and add the user to the totalActiveUser
      //should add an object to the totalActiveUser with the props total users, week users, week number
      totalActiveUser[thisWeekCreatedAt] = {
        // week: (totalActiveUser[thisWeekCreatedAt]?.week || 0) + 1,
        weekNumber: thisWeekCreatedAt,
        activeUsers: [...totalNumberOfUsers],
        atibutedUsers: [...totalNumberOfAtributedUsers],
      };

      //lets now check the history object to check for status changes
      a?.history?.map((h) => {
        let currentweekDay = moment(h.date); // this is timestamp lets convert it to week
        let thisWeekCreatedAt = Number(
          `${currentweekDay.format("YY")}${
            currentweekDay.isoWeek() < 10
              ? `0${currentweekDay.isoWeek()}`
              : currentweekDay.isoWeek()
          }`
        );

        if (thisWeekCreatedAt) {
          let weekDidNotExits = !totalActiveUser[thisWeekCreatedAt]
            ? false
            : true;

          //     //now lets check if the status is 1 so that we can remove it from the week and add it to the totalAtributedUser
          if (Number(h.status) === 6 || Number(h.status) === 1) {
            let thisWeekTotalNumberOfUsers =
              totalActiveUser[thisWeekCreatedAt]?.activeUsers || [];
            let thisWeekTotalNumberOfAtributedUsers =
              totalNumberOfAtributedUsers[thisWeekCreatedAt]?.atibutedUsers ||
              [];

            //lets find the index of a.id in totalNumberOfUsers
            if (thisWeekTotalNumberOfUsers.indexOf(a.id) > -1)
              thisWeekTotalNumberOfUsers.splice(
                thisWeekTotalNumberOfUsers.indexOf(a.id),
                1
              );
            thisWeekTotalNumberOfAtributedUsers.push(a.id);
            thisWeekTotalNumberOfAtributedUsers = [
              ...new Set(thisWeekTotalNumberOfAtributedUsers),
            ];
            totalActiveUser[thisWeekCreatedAt] = {
              // week: (totalActiveUser[thisWeekCreatedAt]?.week || 0) - 1,
              weekNumber: thisWeekCreatedAt,
              activeUsers: [...thisWeekTotalNumberOfUsers],
              atibutedUsers: [...thisWeekTotalNumberOfAtributedUsers],
              weekDidNotExits,
            };
          } else if (
            Number(h.status) === 0 ||
            Number(h.status) === 7 ||
            Number(h.status) === 9 ||
            Number(h.status) === 10 ||
            Number(h.status) === 11
          ) {
            let thisWeekTotalNumberOfUsers =
              totalActiveUser[thisWeekCreatedAt]?.activeUsers || [];
            let thisWeekTotalNumberOfAtributedUsers =
              totalNumberOfAtributedUsers[thisWeekCreatedAt]?.atibutedUsers ||
              [];

            // //lets find the index of a.id in totalNumberOfUsers
            if (thisWeekTotalNumberOfAtributedUsers.indexOf(a.id) > -1)
              thisWeekTotalNumberOfAtributedUsers.splice(
                thisWeekTotalNumberOfAtributedUsers.indexOf(a.id),
                1
              );
            thisWeekTotalNumberOfUsers.push(a.id);
            thisWeekTotalNumberOfUsers = [
              ...new Set(thisWeekTotalNumberOfUsers),
            ];
            totalActiveUser[thisWeekCreatedAt] = {
              // week: (totalActiveUser[thisWeekCreatedAt]?.week || 0) + 1,
              weekNumber: thisWeekCreatedAt,
              activeUsers: [...thisWeekTotalNumberOfUsers],
              atibutedUsers: [...thisWeekTotalNumberOfAtributedUsers],
              weekDidNotExits,
            };
          }
        }
      });

      // console.log(a);
      return a;
    });

    //normalize values
    Object.keys(totalActiveUser).map((a) => {
      if (totalActiveUser[a].weekDidNotExits) {
        let weekIndex = Object.keys(totalActiveUser).indexOf(`${a}`);
        if (weekIndex > 0) {
          let previousWeek = Object.keys(totalActiveUser)[weekIndex - 1];
          totalActiveUser[a] = {
            ...(totalActiveUser[a] || {}),
            activeUsers: [
              ...new Set([
                ...totalActiveUser[previousWeek]?.activeUsers,
                ...totalActiveUser[a]?.activeUsers,
              ]),
            ],
            atibutedUsers: [
              ...new Set([
                ...totalActiveUser[previousWeek]?.atibutedUsers,
                ...totalActiveUser[a]?.atibutedUsers,
              ]),
            ],
          };
        }
      }
      return a;
    });
    // if (!this.props.data?.getAtributedUsersPerWeek) return null;
    let runningValue = 0;
    return outputWeeks.reverse().map((val, week) => {
      const weekRunRever = weekRun.sort().reverse();
      const thisValue = totalActiveUser[weekRunRever[week]];
      runningValue = thisValue?.activeUsers
        ? thisValue?.activeUsers?.length
        : runningValue;

      //if runningValue === 0 we should display the last value
      if (runningValue < 100) {
        //find index of this value in the array and show the last value
        let lastValue = 0;
        let lastWeek = 0;
        for (let i = week; i < outputWeeks.length; i++) {
          const thisValue = totalActiveUser[weekRunRever[i]];
          lastValue = thisValue?.activeUsers
            ? thisValue?.activeUsers?.length
            : lastValue;
          lastWeek = weekRunRever[i];
          if (lastValue > 0) break;
        }
        runningValue = lastValue;
      }
      return (
        <TotalParcelPerWeek key={week}>
          <span className={"week"}>{weekRunRever[week]}</span>
          <span className={"val"}>{runningValue}</span>
        </TotalParcelPerWeek>
      );
    });
  }

  getActiveContacts() {
    // const currentweek = moment().isoWeek();
    // let outputWeeks = [...Array(currentweek)].map((x, i) => 0);
    let outputWeeks = [...Array(52)].map((x, i) => {});
    let weekRun = [];
    const waitingUsers = this.props.data?.getActiveContacts?.map((a) => {
      //let add a week Number YYWW to the object for easy retrieval
      let currentweekDay = moment(a.week);
      a.weekNumber = Number(
        `${currentweekDay.format("YY")}${
          currentweekDay.isoWeek() < 10
            ? `0${currentweekDay.isoWeek()}`
            : currentweekDay.isoWeek()
        }`
      );
      return a;
    });
    let previousWeekRecord = 0;

    if (!this.props.data?.getActiveContacts) return null;
    //lets now add it to the output
    outputWeeks = outputWeeks.map((a, week) => {
      let currentweekDay = moment().subtract(51 - week, "weeks");
      const currentweek = Number(
        `${currentweekDay.format("YY")}${
          currentweekDay.isoWeek() < 10
            ? `0${currentweekDay.isoWeek()}`
            : currentweekDay.isoWeek()
        }`
      );
      weekRun.push(currentweek);
      const thisWeek = currentweek;
      let weekVal = 0;

      const thisRecords = waitingUsers.find((th) => th.weekNumber === thisWeek);

      if (thisRecords) {
        weekVal = thisRecords.sum;
        previousWeekRecord = thisRecords.sum;
      } else {
        weekVal = previousWeekRecord;
      }

      return weekVal;
    });

    return outputWeeks.reverse().map((val, week) => {
      const weekRunRever = weekRun.sort().reverse();
      return (
        <TotalParcelPerWeek key={week}>
          <span className={"week"}>{weekRunRever[week]}</span>
          <span className={"val"}>{val}</span>
        </TotalParcelPerWeek>
      );
    });
  }

  inpectionsLess1Week() {
    // const currentweek = moment().isoWeek();
    // let outputWeeks = [...Array(currentweek)].map((x, i) => 0);
    let outputWeeks = [...Array(52)].map((x, i) => {});
    let weekRun = [];
    const parcelBadRatingHistory = this.state.parcelBadRatingHistory;
    if (!this.state.parcelBadRatingHistory) return null;
    //lets now add it to the output
    outputWeeks = outputWeeks.map((a, week) => {
      let currentweekDay = moment().subtract(51 - week, "weeks");
      const currentweek = Number(
        `${currentweekDay.format("YY")}${
          currentweekDay.isoWeek() < 10
            ? `0${currentweekDay.isoWeek()}`
            : currentweekDay.isoWeek()
        }`
      );
      weekRun.push(currentweek);
      const thisWeek = currentweek;
      let weekVal = 0;
      let addedParcel = [];
      Object.keys(parcelBadRatingHistory).map((h) => {
        parcelBadRatingHistory[h].map((th) => {
          //lets makesure we justcount 1 parcel per week
          const composedName = `${th.park}${th.label}`;
          if (addedParcel.indexOf(composedName) > -1) return null;
          if (th.weekStart && th.weekEnd) {
            if (thisWeek >= th.weekStart && thisWeek <= th.weekEnd) {
              weekVal++;
              addedParcel.push(composedName);
            }
          } else if (th.weekStart) {
            if (thisWeek >= th.weekStart) {
              weekVal++;
              addedParcel.push(composedName);
            }
          }
        });
      });
      return { value: weekVal, addedParcel };
    });

    return outputWeeks.reverse().map((val, week) => {
      const weekRunRever = weekRun.sort().reverse();
      return (
        <TotalParcelPerWeek key={week}>
          <span className={"week"}>{weekRunRever[week]}</span>
          <Tooltip
            title={
              <div>
                {val.addedParcel.map((p) => (
                  <div>{p}</div>
                ))}
              </div>
            }
          >
            <span className={"val"}>{val.value}</span>
          </Tooltip>
        </TotalParcelPerWeek>
      );
    });

    // outputWeeks.map();
  }

  inpectionsAverageTimeLess1() {
    let outputWeeks = [...Array(52)].map((x, i) => {});
    let weekRun = [];
    const parcelBadRatingHistory = this.state.parcelBadRatingHistory;
    if (!this.state.parcelBadRatingHistory) return null;
    //lets now add it to the output
    outputWeeks = outputWeeks.map((a, week) => {
      let currentweekDay = moment().subtract(51 - week, "weeks");
      const currentweek = Number(
        `${currentweekDay.format("YY")}${
          currentweekDay.isoWeek() < 10
            ? `0${currentweekDay.isoWeek()}`
            : currentweekDay.isoWeek()
        }`
      );
      weekRun.push(currentweek);
      const thisWeek = currentweek;
      let weekVal = 0;
      let weekItem = 0;
      let addedParcel = [];
      Object.keys(parcelBadRatingHistory).map((h) => {
        parcelBadRatingHistory[h].map((th) => {
          const composedName = `${th.park}${th.label}`;

          if (th.weekStart && th.weekEnd) {
            if (thisWeek >= th.weekStart && thisWeek <= th.weekEnd) {
              const startDate = moment(th.start);
              const endDate = moment(currentweekDay);
              const days = endDate.diff(startDate, "days");
              ///we cam have more than one inspection with value less than 1

              if (
                addedParcel[composedName] &&
                addedParcel[composedName] < days
              ) {
                weekVal = weekVal + (addedParcel[composedName] - days);
                addedParcel[composedName] = days;
              } else if (!addedParcel[composedName]) {
                weekVal = weekVal + days;
                weekItem++;
                addedParcel[composedName] = days;
              }
            }
          } else if (th.weekStart) {
            if (thisWeek >= th.weekStart) {
              const startDate = moment(th.start);
              const endDate = moment(currentweekDay);
              const days = endDate.diff(startDate, "days");
              ///we cam have more than one inspection with value less than 1
              if (
                addedParcel[composedName] &&
                addedParcel[composedName] < days
              ) {
                weekVal = weekVal + (addedParcel[composedName] - days);
                addedParcel[composedName] = days;
              } else if (!addedParcel[composedName]) {
                weekVal = weekVal + days;
                weekItem++;
                addedParcel[composedName] = days;
              }
            }
          }
        });
      });

      return (weekVal / weekItem).toFixed(1);
    });
    return outputWeeks
      .reverse()
      .map((val, week) => {
        const weekRunRever = weekRun.sort().reverse();
        return (
          <TotalParcelPerWeek key={week}>
            <span className={"week"}>{weekRunRever[week]}</span>
            <span className={"val"}>{val}</span>
          </TotalParcelPerWeek>
        );
      })
      .slice(0, 52);

    // outputWeeks.map();
  }

  topTenWorstParcels() {
    const parcelBadRatingHistory = this.state.parcelBadRatingHistory;
    if (!this.state.parcelBadRatingHistory) return null;
    const listOfParcels = [];
    Object.keys(parcelBadRatingHistory).map((h) => {
      parcelBadRatingHistory[h].map((th) => {
        if (th.weekStart && !th.weekEnd) {
          if (!listOfParcels.find((p) => p.id === h))
            listOfParcels.push({ ...th, id: th.origin || h });
        }
      });
    });
    return listOfParcels
      .map((p) => {
        const currDate = moment();
        const dateToTest = moment(p.start);
        return { ...p, days: currDate.diff(dateToTest, "days") };
      })
      .sort((a, b) => b.days - a.days)
      .map((p, i) => {
        if (i > 19) return null;
        return (
          <li key={i}>
            <div className={"icon"}>{p.days} dias</div>
            <div className={"content"}>
              {p.park}
              <br />
              {p.label}
            </div>
          </li>
        );
      });
  }

  render() {
    const d = new Date();
    const time = d.getHours();
    const greet =
      time < 12
        ? `bom dia ${this.props.user.name}`
        : `boa tarde ${this.props.user.name}`;
    const { data, menuInspections, menuInterventions } = this.props;
    // let totalFreguesias = 0;
    const maintenanceFreguesias = this.handleMaintenanceFreguesias();
    const hortasList = this.getMultilevelParcelList();
    return (
      <StyledWrap dashboard>
        <Grid fluid>
          <PageTitle>Olá, {greet}</PageTitle>
          <Row>
            <Col xs={12} sm={7} md={7} lg={7}>
              <SectionTitle>
                <i className={`icon-${menuInspections.icon} icons`} />{" "}
                {menuInspections.name} por semana
              </SectionTitle>
              <StyledContentWrapFullHeight>
                {data && (
                  <Bar
                    data={this.generateGraphData()}
                    options={{
                      maintainAspectRatio: false,
                    }}
                    width={600}
                    height={300}
                  />
                )}
              </StyledContentWrapFullHeight>
            </Col>
            <Col xs={12} sm={5} md={5} lg={5}>
              <SectionTitle>
                <i className={`icon-puzzle icons`} /> Total de ocupação de
                parcelas{" "}
                <Select
                  style={{
                    marginLeft: "10px",
                    background: "transparent",
                    fontSize: "1em",
                  }}
                  value={this.state.radarType}
                  onChange={(e) => {
                    this.setState({ radarType: e.target.value });
                    // this.setBadRatingHistory();
                  }}
                >
                  <MenuItem value={"HC"}>Hortas Comunitarias</MenuItem>
                  <MenuItem value={"PC"}>Produção Comunitarias</MenuItem>
                </Select>
              </SectionTitle>
              <StyledContentWrapFullHeight>
                {data && (
                  <Radar
                    data={this.generateRadarData()}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      // tooltips: {
                      //   callbacks: {
                      //     label: (tooltipItem, data) => {
                      //       const label = data.labels[tooltipItem.index];
                      //       const valueAvailable =
                      //         data.datasets[2].data[tooltipItem.index];
                      //       const valueEmpty =
                      //         data.datasets[1].data[tooltipItem.index];
                      //       return `${label}-> ${valueAvailable} parcelas, ${valueEmpty} vazias`;
                      //     },
                      //   },
                      // },
                    }}
                    width={600}
                    height={600}
                  />
                )}
              </StyledContentWrapFullHeight>
            </Col>
          </Row>
          <br />
          <br />
          <Row style={{ justifyContent: "space-between" }}>
            <Col xs={12} sm={6} md={6} lg={6}>
              <SectionTitle>{"Hortelões em lista de espera"}</SectionTitle>
              <StyledContentWrap>
                <TotalParcelPerWeekWrap>
                  {this.getAtributedContacts()}
                  {/* {this.getActiveContacts()} */}
                </TotalParcelPerWeekWrap>
              </StyledContentWrap>

              <SectionTitle>
                <i className={`icon-${menuInspections.icon} icons`} />{" "}
                {"Parcelas com avaliação <= 1"}
              </SectionTitle>
              <StyledContentWrap>
                <TotalParcelPerWeekWrap>
                  {this.inpectionsLess1Week()}
                </TotalParcelPerWeekWrap>
              </StyledContentWrap>
              <br />
              <SectionTitle>
                <i className={`icon-${menuInspections.icon} icons`} />{" "}
                {"Média de dias com avaliação <= 1"}
              </SectionTitle>
              <StyledContentWrap>
                <TotalParcelPerWeekWrap>
                  {this.inpectionsAverageTimeLess1()}
                </TotalParcelPerWeekWrap>
              </StyledContentWrap>
            </Col>
            <Col xs={12} sm={5} md={5} lg={5}>
              <SectionTitle>
                <i className={`icon-puzzle icons`} /> Estado de parcelas
                <Select
                  style={{
                    marginLeft: "10px",
                    background: "transparent",
                    fontSize: "1em",
                  }}
                  value={this.state.radarType}
                  onChange={(e) => {
                    this.setState({ radarType: e.target.value });
                    // this.setBadRatingHistory();
                  }}
                >
                  <MenuItem value={"HC"}>Hortas Comunitarias</MenuItem>
                  <MenuItem value={"PC"}>Produção Comunitarias</MenuItem>
                </Select>
              </SectionTitle>
              <StyledContentWrapFullHeight>
                {data && (
                  <Doughnut
                    data={this.generateDoughnutData()}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      tooltips: {
                        enabled: true,
                        callbacks: {
                          label: function(tooltipItem, chartData) {
                            //datasetIndex
                            const dataset =
                              chartData.datasets[tooltipItem.datasetIndex];
                            const label = dataset.label[tooltipItem.index];
                            const value = dataset.data[tooltipItem.index];
                            return `${label}: ${value}`;
                          },
                          // Optionally, you can customize the title or other parts of the tooltip
                          title: function(tooltipItems, chartData) {
                            // return "Custom Title"; // Customize the title if needed
                          },
                        },
                        // You can also customize the tooltip appearance
                        backgroundColor: "rgba(0,0,0,0.7)",
                        titleFontSize: 14,
                        titleFontColor: "#fff",
                        bodyFontColor: "#fff",
                        bodyFontSize: 12,
                        displayColors: false,
                      },
                    }}
                    width={600}
                    height={450}
                  />
                )}
              </StyledContentWrapFullHeight>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
              <SectionTitle>Última {menuInspections.name}</SectionTitle>
              <StyledContentWrapFullHeight>
                <Hortas>
                  {data.lastVisited &&
                    data.lastVisited
                      .map((horta) => {
                        const currDate = moment();
                        const dateToTest = moment(horta.date);
                        return {
                          ...horta,
                          date: currDate.diff(dateToTest, "days"),
                        };
                      })
                      .sort((a, b) => b.date - a.date)
                      .map((horta) => {
                        return (
                          <li>
                            <div className={"icon"}>{horta.date} dias</div>
                            <div className={"content"}>
                              {horta["número_ant"]}
                            </div>
                          </li>
                        );
                      })}
                </Hortas>
                {/* {data && (
                  <HorizontalBar
                    options={{
                      maintainAspectRatio: false,
                    }}
                    // legend={{ display: false }}
                    height={300}
                    data={this.generateRadarData()}
                  />
                )} */}
              </StyledContentWrapFullHeight>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
              <SectionTitle>
                <i className={`icon-${menuInspections.icon} icons`} />{" "}
                {"Hortas com avaliação <= 1"}
              </SectionTitle>
              <StyledContentWrapFullHeight>
                <Hortas>{this.parksWithMoreBadParcels()}</Hortas>
              </StyledContentWrapFullHeight>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
              <SectionTitle>
                <i className={`icon-${menuInspections.icon} icons`} />{" "}
                {"Top 20 Parcelas mais antigas com avaliação <= 1"}
              </SectionTitle>
              <StyledContentWrapFullHeight>
                <Hortas>{this.topTenWorstParcels()}</Hortas>
              </StyledContentWrapFullHeight>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs className={"m-b-15"}>
              <Row>
                <Col xs className={"m-b-15"}>
                  <SectionTitle>
                    <i className={`icon-${menuInspections.icon} icons`} />{" "}
                    {menuInspections.name} Rotas{" "}
                  </SectionTitle>
                  <StyledContentWrap>
                    <ul>
                      {data &&
                        data.inespectionPerRout &&
                        this.state.parklist &&
                        this.itemPerRout(
                          data.inespectionPerRout,
                          menuInspections.icon
                        )}
                    </ul>
                  </StyledContentWrap>
                </Col>
                <Col xs className={"m-b-15"}>
                  <SectionTitle>
                    <i className={`icon-${menuInterventions.icon} icons`} />{" "}
                    {menuInterventions.name} Rotas{" "}
                  </SectionTitle>
                  <StyledContentWrap>
                    <ul>
                      {data &&
                        data.maintenancePerRout &&
                        this.state.parklist &&
                        this.itemPerRout(
                          data.maintenancePerRout,
                          menuInterventions.icon
                        )}
                    </ul>
                  </StyledContentWrap>
                </Col>
              </Row>
            </Col>
            <Col xs className={"m-b-15"}>
              <SectionTitle>Rotas sem registo</SectionTitle>
              <Row>
                <Col xs className={"m-b-15"}>
                  <StyledContentWrap>
                    <ul>
                      {data &&
                        data.notVisitedInspectionParks &&
                        this.itemPerRoutNotVisited(
                          data.notVisitedInspectionParks,
                          menuInspections.icon
                        )}
                    </ul>
                  </StyledContentWrap>
                </Col>
                <Col xs className={"m-b-15"}>
                  <StyledContentWrap>
                    <ul>
                      {data &&
                        data.notVisitedMaintenanceParks &&
                        this.itemPerRoutNotVisited(
                          data.notVisitedMaintenanceParks,
                          menuInterventions.icon
                        )}
                    </ul>
                  </StyledContentWrap>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row />
          {/* <br />
          <br />
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Row>
                {this.props.role !== "EV" && (
                  <Col xs className={"m-b-15"}>
                    <SectionTitle>
                      <i className={`icon-camera icons`} />{" "}
                      {menuInspections.name} Equipa
                    </SectionTitle>
                    <StyledContentWrap>
                      <ul>
                        {data &&
                          data.inspectionsTop5Teams &&
                          data.inspectionsTop5Teams.map((team, i) => (
                            <li key={i}>
                              <span>{team.inspections}</span>{" "}
                              {team.label || "Admin"}
                            </li>
                          ))}
                      </ul>
                    </StyledContentWrap>
                  </Col>
                )}
                <Col xs className={"m-b-15"}>
                  <SectionTitle>
                    <i className={`icon-camera icons`} /> {menuInspections.name}{" "}
                    Freguesias
                  </SectionTitle>
                  <StyledContentWrap>
                    <ul>
                      {data &&
                        data.inespectionsTop5Freguesias &&
                        data.inespectionsTop5Freguesias.map((freg, i) => {
                          // totalFreguesias += Number(freg.inspections);
                          return (
                            <li key={i}>
                              <span>{freg.inspections}</span> {freg.area}
                            </li>
                          );
                        })}
                      {
                        <li className={"total"}>
                          // <span>{totalFreguesias}</span> Total
                        </li>
                      }
                    </ul>
                  </StyledContentWrap>
                </Col>
              </Row>
            </Col>
            {this.props.role !== "EV" && (
              <Col xs={12} sm={6} md={6} lg={6}>
                <Row>
                  <Col xs className={"m-b-15"}>
                    <SectionTitle>
                      <i className={`icon-wrench icons`} />{" "}
                      {menuInterventions.name} Equipa
                    </SectionTitle>
                    <StyledContentWrap>
                      <ul>
                        {data &&
                          data.maintenanceTop5Teams &&
                          data.maintenanceTop5Teams.map((team, i) => (
                            <li key={i}>
                              <span>{team.maintenance}</span>{" "}
                              {team.label || "Admin"}
                            </li>
                          ))}
                      </ul>
                    </StyledContentWrap>
                  </Col>
                  <Col xs className={"m-b-15"}>
                    <SectionTitle>
                      <i className={`icon-wrench icons`} />{" "}
                      {menuInterventions.name} Freguesias
                    </SectionTitle>
                    <StyledContentWrap>
                      <ul>
                        {Object.keys(maintenanceFreguesias).map((freg, i) => (
                          <li key={i}>
                            <span>{maintenanceFreguesias[freg]}</span> {freg}
                          </li>
                        ))}
                      </ul>
                    </StyledContentWrap>
                  </Col>
                </Row>
              </Col>
            )}
          </Row> */}
          <br />
          <br />
        </Grid>
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.data,
      geoJson: state.parklist.geoJson,
      role: state.user.data.role,
      data: state.dashboard.data,
      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  {
    getStats,
    setActivePark,
    toggleEditCommunication,
    toggleViewGrowers,
    toggleAddInspection,
  }
)(DashboardPage);

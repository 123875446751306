import React from "react";
import styled from "styled-components";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const getMuiTheme = () =>
  createTheme({
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MuiTableBody: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
      MUIDataTableHeadRow: {
        root: {
          verticalAlign: "bottom",
        },
      },

      MuiTableRow: {
        root: {},
      },
      MUIDataTableBodyCell: {
        root: {
          paddingTop: "1em",
          paddingBottom: "1em",
        },
      },
      MUIDataTableToolbar: {
        actions: {
          paddingRight: "140px",
        },
      },

      MUIDataTableToolbarSelect: {
        root: {
          // justifyContent: "flex-start",
          // paddingTop: "3vh",
          paddingRight: "140px",
        },
        title: {
          margin: "0 5px",
        },
      },
      MUIDataTable: {
        root: {
          backgroundColor: "#FF000",
          maxHeight: "100%",
          height: "80vh",
        },
        paper: {
          boxShadow: "none",
          background: "transparent",
        },
        responsiveScroll: {
          maxHeight: "none",
          background: "#fff",
          borderRadius: "4px",
          boxShadow:
            "0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#f4f6f8",
          zIndex: 9,
        },
      },
      MuiChip: {
        root: {
          margin: ".2em",
        },
      },
    },
  });

const RootWrapper = styled.div`
  && {
    width: 100%;
    /* padding-top: 7%; */
    @media (max-width: 455px) {
      margin: 0 0 30vh 0;
    }
  }
`;

const TableWrapper = styled.div`
  && {
  }
`;

class EnhancedTable extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: "asc",
      orderBy: props.orderBy,
      selected: [],
      page: 0,
      rowsPerPage: 50,
      rowsExpanded: [],
    };
  }

  handleClick = (data) => {
    if (this.props.click) {
      return this.props.click(data.id);
    } else if (this.props.clickWithData) {
      return this.props.clickWithData(data);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  filterData = (header, data) => {
    let outputData = [];
    data.map((row) => {
      let thisData = [];
      header.map((col) => {
        thisData.push(row[col.id] || "");
        return col;
      });
      outputData.push(thisData);
      return row;
    });
    return outputData;
  };

  updateRowsExpanded(allRowsExpanded) {
    let rowsExpanded = allRowsExpanded.map((item) => {
      return item.dataIndex;
    });

    if (this.props.setRowsExpanded) {
      this.props.setRowsExpanded(rowsExpanded);
    } else {
      this.setState(rowsExpanded);
    }
  }
  render() {
    const { data, header } = this.props;

    if (!data)
      return (
        <RootWrapper>
          <TableWrapper>Não existe conteudo</TableWrapper>
        </RootWrapper>
      );

    const thisData = this.filterData(header, data);
    const options = {
      filter: true,
      // count: thisData.length || 0,
      // fixedHeaderOptions: { xAxis: true, yAxis: true },
      fixedHeader: true,
      fixedSelectColumn: true,
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      // isRowSelectable: this.props.selectable ? true : false,
      selectableRows: this.props.selectable ? "multiple" : "none",
      // responsive: "scrollFullHeight",
      // tableBodyHeight: "calc( 100vh - 200px )",
      tableBodyHeight: "calc( 100vh - 120px )",
      downloadOptions: {
        filename: "report.csv",
        separator: ";",
        filterOptions: {
          useDisplayedColumnsOnly: !this.props.dontUseDisplayedColumnsOnly,
          useDisplayedRowsOnly: !this.props.dontUseDisplayedRowsOnly,
        },
      },

      //expandableRows props
      onRowsExpand: (currentRowsExpanded, allRowsExpanded) => {
        this.updateRowsExpanded(allRowsExpanded);
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const processedDataCSV =
          (this.props.preprocessExport &&
            this.props.preprocessExport(data, columns)) ||
          data;
        return "\uFEFF" + buildHead(columns) + buildBody(processedDataCSV);
      },
      rowsExpanded: this.props.rowsExpanded || this.state.rowsExpanded,
      expandableRowsHeader: false,
      expandableRows: this.props.renderExpandableRow ? true : false,
      expandableRowsOnClick: this.props.renderExpandableRow ? true : false,
      renderExpandableRow: this.props.renderExpandableRow,
      // onDownload: (buildHead, buildBody) => buildHead(header) + buildBody(data),
      rowsSelected: this.props.rowsSelected || [],
      customToolbarSelect: this.props.selectCustomAction,
      customTableBodyFooterRender: (opts) =>
        this.props.customTableBodyFooterRender &&
        this.props.customTableBodyFooterRender(opts),
      onRowClick: (rowData, rowMeta) => {
        this.handleClick(this.props.data[rowMeta.dataIndex]);
      },
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 250, 1000, 5000],
      onColumnSortChange: (changedColumn, direction) => {
        this.props.onColumnSortChange &&
          this.props.onColumnSortChange(changedColumn, direction);
      },

      // customRowRender: (rowdata, dataIndex, rowIndex) => {
      //   console.log(rowdata, dataIndex);
      // },
    };

    return (
      <RootWrapper>
        <TableWrapper>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={""}
              data={thisData}
              columns={header}
              options={options}
            />
          </MuiThemeProvider>
        </TableWrapper>
      </RootWrapper>
    );
  }
}

export default EnhancedTable;

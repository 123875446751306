import styled from "styled-components";

export const ImageContainer = styled.div`
  && {
    width: 70px;
    height: 70px;
    background: url("${(props) => props.src}");
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #ccc;
  }
`;
export const ButtonIcon = styled.div`
  && {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 1.2em;
    background: #28587f;
    padding: 0.4em;
    border-radius: 8px;
    /* background: #fafafa; */
    color: #fff;
    ${(props) => props.primary && `background: #fff; color: #4da1ff;`}
    ${(props) => props.formed && `background: #4da1ff; color: #fff;`}
    ${(props) => props.active && `background: #8e3810; color: #fff;`}
    box-shadow: 0px 2px 3px 2px #e0e0e0;
    margin: 0 0.5em 0 0;
    &:hover {
      background: #f0f0f0;
      color: #4da1ff;
    }
  }
`;
export const ActionsWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 200px;
    ${(props) =>
      props.width && `min-width: ${props.width}; width:${props.width};`}
    border-radius: 6px;
    padding: 0.2em;
    margin: 0 0 0.5em 0;
  }
`;

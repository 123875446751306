import { MenuItem } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { FiUsers } from "react-icons/fi";
import { IoArrowUndoCircle } from "react-icons/io5";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import SelectComponent from "../../components/form-wrappers/Select";
import Content from "../../components/Layout/Content";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import Sidebar from "../../components/Layout/Sidebar";
import { apiAddress } from "../../config/general";
import { store } from "../../store/configure";
import { Tooltip } from "@material-ui/core";

import Diploma from "./Diploma";
import Growers from "./Growers";
import Parks from "./Parks";
import Place from "./Place";
import Training from "./Training";

import {
  FilterList,
  OptionWrapElements,
  SideToggle,
  StyledWrap,
  Input,
} from "./styled";

/**
 * Atributions have 5 stages
 *
 * 1. choose park from list on the side pannel
 * 2. choose from the list of ranks which should be assigned to the park
 * 3. update this list to contain information if user accepted the park or not
 * 4. select this used to give them training
 * 5. after training complete attibute a parcel to the user
 *
 * @param {} param0
 * @returns
 */

const Attributions = ({}) => {
  let { path } = useParams();
  let history = useHistory();

  /***
   * State controller
   */
  const initialView =
    path !== "parks" &&
    path !== "selecteUsers" &&
    path !== "education" &&
    path !== "diplomas" &&
    path !== "attribution"
      ? "parks"
      : path;
  const [view, setView] = useState(initialView); // parks, selecteUsers, education, attribution
  const [parkAttribution, setParkAttribution] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [quotas, setQuotas] = useState({});

  let fetching = false;
  const [selectedPark, setSelectedPark] = useState(null);
  const [selectedTypePark, setSelectedTypePark] = useState(
    "Hortas Comunitárias"
  );
  /**
   * Get Redux default state
   * - user role menu
   * - use role
   * - available parks
   */
  const newState = store.getState();
  const userRole = newState.user.data.role;
  const menuData =
    newState.app?.menus[userRole]?.find(
      (menu) => menu.path === `/attributions`
    ) || {};
  const allParksData = newState.parklist.list;

  const allParkTypes = allParksData.map((park) => park.tipo);
  const uniqueParkTypes = [...new Set(allParkTypes)];

  useEffect(() => {
    getAttributions();
    getParkQuotas();
    // getUserSuplentes();
    return () => {};
    //getAttributionsStatus
  }, []);

  // useEffect(() => {
  //   getUserSuplentes(view);
  //   return () => {};
  // }, [view]);

  useEffect(() => {
    // if (!selectedPark && view === "parks") {
    //   setView("growers");
    // }
    const reloadView =
      path !== "parks" &&
      path !== "selecteUsers" &&
      path !== "education" &&
      path !== "diplomas" &&
      path !== "attribution"
        ? "parks"
        : path;
    setView(reloadView);
    // getUserSuplentes(reloadView);
    return () => {};
    //getAttributionsStatus
  }, [path]);

  /**
   * get park Ranks
   */
  const getParkQuotas = async () => {
    try {
      console.log("getParkQuotas");
      const res = await axios.get(`${apiAddress}api/v1/relations/quotas`);
      setQuotas(res?.data?.msg || {});
    } catch (error) {
      // toastr.error("Aconteceu um erro a criar o material");
      console.log(error);
    }
  };

  /**
   * get users for a park
   */
  const getUserSuplentes = async (reloadView = view) => {
    try {
      if (fetching) return;
      fetching = true;
      let usersToget = [6, 7, 9, 10, 11];
      let withTraining = "";
      if (reloadView === "education") {
        usersToget = [6, 7, 9, 11];
        withTraining = "/active";
      } else if (reloadView === "attribution") {
        usersToget = [6, 9];
      } else if (reloadView === "diplomas") {
        usersToget = [6, 9];
      } else if (reloadView === "contacts") {
        usersToget = [7, 9, 10, 11];
      }

      const res = await axios.get(
        `${apiAddress}api/v1/rankings/status/${usersToget.join(
          ","
        )}${withTraining}`
      );
      //filter attr transfered since status does not change
      setSelectedUsers(
        res?.data?.msg?.filter((u) => {
          if (u.status === 7 && Number(u.attrStatus) !== 6) {
            return false;
          }
          return true;
        }) || []
      );
      fetching = false;
    } catch (error) {
      // toastr.error("Aconteceu um obter lista");
      console.log(error);
      fetching = false;
    }
  };

  const finishProcess = async () => {
    const toastrConfirmOptions = {
      onOk: async () => {
        try {
          const res = await axios.get(
            `${apiAddress}api/v1/contacts/finishAttributionprocess`
          );
          getUserSuplentes();
          toastr.success("Processo de atribuição terminado com sucesso");
          setView("parks");
        } catch (error) {
          // toastr.error("Aconteceu um obter lista");
          console.log(error);
        }
      },
      onCancel: () => console.log("CANCEL: clicked"),
    };

    toastr.confirm(
      `Ao terminar o processo de atribuições, todos os utilizadores não atribuidos passam novamente ao estado ativo. 
      Esta acção não pode ser revertida`,
      toastrConfirmOptions
    );
  };

  /**
   * get atributions satus for all pid
   */
  const getAttributions = async () => {
    try {
      const res = await axios.get(
        `${apiAddress}api/v1/elements/attributionstatus`
      );
      setParkAttribution(res.data.msg);
    } catch (error) {
      toastr.error("Aconteceu um erro a criar o material");
      console.log(error);
    }
  };

  /**
   * Get parks as the first filter
   * if should include all kinds of parks
   * there should be a selectated park type list
   */
  const ParkSelection = ({ selectedUsersLocal }) => {
    const [searchPark, setSearchPark] = useState(null);
    return (
      <>
        <SelectComponent
          value={selectedTypePark}
          label="Tipo"
          onChange={(e) => setSelectedTypePark(e.target.value)}
          name="type"
          meta={{ invalid: false }}
          style={{ width: "100%" }}
        >
          {uniqueParkTypes &&
            uniqueParkTypes.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
        </SelectComponent>

        {view === "diplomas" && (
          <div style={{ position: "relative" }}>
            <OptionWrapElements
              fixed
              onClick={() => {
                finishProcess();
              }}
              selected={selectedPark === "all"}
            >
              <div className="places">
                <div className="total">
                  <IoArrowUndoCircle />
                </div>
              </div>
              <div className="details">
                <div className="name" style={{ fontSize: "1.2em" }}>
                  Terminar atribuições?
                </div>
                <div className="sidebside">
                  <div className="freguesia">
                    Não atribuidos voltam para o estado "Ativo". Não atende
                    passam para inativos, e atribuidos Pendente mantêm o estado.
                  </div>
                </div>
              </div>
            </OptionWrapElements>

            {/* {selectedPark === park.numero && (
              <StyledLineButton>Atribuir</StyledLineButton>
            )} */}
          </div>
        )}
        <Input
          placeholder="Pesquisar"
          style={{ margin: "1em 0 0 0" }}
          onChange={(e) => setSearchPark(e.target.value)}
        />
        {view !== "parks" && view !== "attribution" && (
          <div style={{ position: "relative" }}>
            <OptionWrapElements
              onClick={() => {
                // if (selectedPark === park.numero) {
                //   setSelectedPark(null);
                //   // setView("growers");
                // } else {
                //   setSelectedPark(park.numero);
                // }
                setSelectedPark("all");
              }}
              selected={selectedPark === "all"}
            >
              <div className="details">
                <div className="name">Todo o Concelho</div>
                <div className="sidebside">
                  <div className="freguesia"> Cascais</div>
                </div>
              </div>
            </OptionWrapElements>

            {/* {selectedPark === park.numero && (
              <StyledLineButton>Atribuir</StyledLineButton>
            )} */}
          </div>
        )}

        {allParksData
          .filter((p) => {
            let shouldReturn = true;
            if (searchPark && searchPark !== "" && p?.nome) {
              shouldReturn =
                p?.nome?.toLowerCase().includes(searchPark.toLowerCase()) ||
                p["número_ant"]
                  ?.toLowerCase()
                  .includes(searchPark.toLowerCase()) ||
                p?.freguesia?.toLowerCase().includes(searchPark.toLowerCase());
            }
            return shouldReturn && p.tipo === selectedTypePark;
          })
          .sort((a, b) => {
            const thisPark = parkAttribution.find((p) => p.pid === a.numero);
            const otherPark = parkAttribution.find((p) => p.pid === b.numero);
            const availa = thisPark?.available - thisPark?.attr;
            const availb = otherPark?.available - otherPark?.attr;
            return availb - availa;
          })
          .map((park, i) => {
            const thisPark = parkAttribution.find((p) => p.pid === park.numero);
            let totalRank0 = 0;
            let totalRank1 = 0;
            let totalRank2 = 0;
            //get users from selectedUsers that have rank for this park
            const totalUsersSelected = selectedUsersLocal.filter((user) => {
              if (user?.status === 7 && Number(user?.attrStatus) !== 6) return;

              if (user?.ranks[park.numero]?.ranking1) {
                totalRank0++;
              }
              if (user?.ranks[park.numero]?.ranking2) {
                totalRank1++;
              }
              if (user?.ranks[park.numero]?.rankingFreguesia) {
                totalRank2++;
              }
              return user?.ranks[park.numero];
            })?.length;

            if (!selectedPark && i === 0 && parkAttribution.length > 0)
              setSelectedPark(park.numero);

            const thisParkQuotas = quotas[park.numero] || {};
            return (
              <div key={i} style={{ position: "relative" }}>
                <OptionWrapElements
                  onClick={() => {
                    // if (selectedPark === park.numero) {
                    //   setSelectedPark(null);
                    //   // setView("growers");
                    // } else {
                    //   setSelectedPark(park.numero);
                    // }
                    setSelectedPark(park.numero);
                  }}
                  selected={selectedPark === park.numero}
                >
                  {thisPark?.available && thisPark?.attr && (
                    <div className="places">
                      <div className="total">
                        {thisPark?.available - thisPark?.attr}
                      </div>
                      {totalUsersSelected > 0 && (
                        <div className="totalUsers">
                          {totalUsersSelected}
                          <span>
                            <FiUsers />
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="details">
                    <div className="name">
                      {park.nome}{" "}
                      {park["número_ant"] ? ` ${park["número_ant"]}` : ""}
                    </div>
                    <div className="sidebside">
                      <div className="freguesia"> {park.freguesia}</div>
                    </div>
                    <div className="quotas">
                      {thisParkQuotas.ranking0 > 0 && (
                        <div
                          className={`r0 ${
                            totalRank0 < thisParkQuotas.ranking0
                              ? "colorRed"
                              : ""
                          }`}
                        >
                          <div className="totalUsers">
                            B {totalRank0}/{thisParkQuotas.ranking0}
                          </div>
                        </div>
                      )}
                      {thisParkQuotas.ranking1 > 0 && (
                        <div
                          className={`r1 ${
                            totalRank1 < thisParkQuotas.ranking1
                              ? "colorRed"
                              : ""
                          }`}
                        >
                          <div className="totalUsers">
                            P {totalRank1}/{thisParkQuotas.ranking1}
                          </div>
                        </div>
                      )}
                      {thisParkQuotas.ranking2 > 0 && (
                        <div
                          className={`r2 ${
                            totalRank2 < thisParkQuotas.ranking2
                              ? "colorRed"
                              : ""
                          }`}
                        >
                          <div className="totalUsers">
                            F {totalRank2}/{thisParkQuotas.ranking2}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </OptionWrapElements>
                {/* <EditParkButton
                  color="primary"
                  onClick={() => viewPark(park.numero)}
                >
                  <MenuIcon className={`icon-note icons`} />
                </EditParkButton> */}
              </div>
            );
          })}
      </>
    );
  };
  //   const listOfParks = newState.parks.data;
  //   const [parks, setParks] = useState([]);

  const menuText = {
    parks: "Selecionar",
    selecteUsers: "Contactos",
    education: "Formação",
    diplomas: "Diplomas",
  };

  /**
   * Render output
   */
  return (
    <StyledWrap>
      <Sidebar
        title={menuText[view] || menuData.name}
        text={`gerir ${menuData.name}`}
        icon={"badge"}
        actions={
          <div>
            {selectedPark && (
              <Tooltip title={`Selecionar Hortelões`}>
                <SideToggle
                  active={view === "parks"}
                  onClick={() => {
                    if (fetching)
                      return toastr.error("Aguarde, pedido por dados em curso");
                    history.push("/attributions/parks");
                  }}
                >
                  <span className="icons icon-map" />
                </SideToggle>
              </Tooltip>
            )}
            {/* <SideToggle
              active={view === "growers"}
              onClick={() => setView("growers")}
            >
              <span className="icons icon-people" />
            </SideToggle> */}
            <Tooltip title={`Contactar Hortelões`}>
              <SideToggle
                active={view === "selecteUsers"}
                onClick={() => {
                  if (fetching)
                    return toastr.error("Aguarde, pedido por dados em curso");
                  history.push("/attributions/selecteUsers");
                  // setSelectedPark("all");
                }}
              >
                <span className="icons icon-phone" />
              </SideToggle>
            </Tooltip>
            <Tooltip title={`Formações`}>
              <SideToggle
                active={view === "education"}
                onClick={() => {
                  if (fetching)
                    return toastr.error("Aguarde, pedido por dados em curso");
                  history.push("/attributions/education");
                }}
              >
                <span className="icons icon-note" />
              </SideToggle>
            </Tooltip>
            <Tooltip title={`Atribuições`}>
              <SideToggle
                active={view === "attribution"}
                onClick={() => {
                  if (fetching)
                    return toastr.error("Aguarde, pedido por dados em curso");
                  history.push("/attributions/attribution");
                }}
              >
                <span className="icons icon-size-actual" />
              </SideToggle>
            </Tooltip>
            <Tooltip title={`Diplomas e Atribuições`}>
              <SideToggle
                active={view === "diplomas"}
                onClick={() => {
                  if (fetching)
                    return toastr.error("Aguarde, pedido por dados em curso");
                  history.push("/attributions/diplomas");
                }}
              >
                <span className="icons icon-badge" />
              </SideToggle>
            </Tooltip>
          </div>
        }
        noanimation
      >
        <FilterList>
          <ScrollArea
            speed={0.8}
            className="maintenance-filter"
            contentClassName="maintenance-filter-content"
            horizontal={false}
          >
            <ParkSelection selectedUsersLocal={selectedUsers} />
          </ScrollArea>
        </FilterList>
      </Sidebar>
      <Content size={2} color={"white"} map={true}>
        {view === "parks" && (
          <Parks
            pid={selectedPark}
            quotas={quotas}
            selectedUsers={selectedUsers}
            getAttributions={getAttributions}
            sideListGetUserSuplentes={getUserSuplentes}
            setSelectedUsers={setSelectedUsers}
            selectedTypePark={selectedTypePark}
          />
        )}
        {view === "selecteUsers" && (
          <Growers
            pid={selectedPark}
            getParkQuotas={getParkQuotas}
            selectedUsers={selectedUsers}
            quotas={quotas}
            getUserSuplentes={getUserSuplentes}
            selectedTypePark={selectedTypePark}
          />
        )}
        {view === "education" && (
          <Training
            pid={selectedPark}
            quotas={quotas}
            getUserSuplentes={getUserSuplentes}
            selectedUsers={selectedUsers}
            selectedTypePark={selectedTypePark}
          />
        )}
        {view === "attribution" && (
          <Place
            pid={selectedPark}
            quotas={quotas[selectedPark] || {}}
            getAttributions={getAttributions}
            getParkQuotas={getParkQuotas}
            sideListGetUserSuplentes={getUserSuplentes}
            selectedTypePark={selectedTypePark}
          />
        )}

        {view === "diplomas" && (
          <Diploma
            pid={selectedPark}
            selectedTypePark={selectedTypePark}
            quotas={quotas}
            sideListGetUserSuplentes={getUserSuplentes}
          />
        )}
      </Content>
    </StyledWrap>
  );
};

export default Attributions;
